@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'JetBrains Mono', Menlo, Monaco, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'JetBrains Mono', Menlo, Monaco, 'Courier New', monospace;
}
html {
  scroll-behavior: smooth;
}